import { ComponentType } from "react";
import { Navigate } from "react-router-dom";
import { setAuthorizationHeader } from "utils/api";
import { PATH_AUTH } from "./path";
import { usePageTracking } from "hooks/usePageTracking";
import KYCGuard from "components/Gaurds/KYCGuard";
const PrivateRouter = ({
  Element,
  requireKYC = true
}: {
  Element: ComponentType;
  requireKYC?: boolean;
}) => {
  usePageTracking();
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to={PATH_AUTH.login} />;
  }
  setAuthorizationHeader(token);
  return <KYCGuard element={Element} requireKYC={requireKYC} data-sentry-element="KYCGuard" data-sentry-component="PrivateRouter" data-sentry-source-file="private_router.tsx" />;
};
export default PrivateRouter;