import React from "react";
import { useFeatureFlagEnabled, useFeatureFlagVariantKey } from "posthog-js/react";
type Props = {
  posthogKey: string;
  children: React.ReactNode;
};
const FeatureGuard = (props: Props) => {
  const isFeatureEnabled = useFeatureFlagEnabled(props.posthogKey);
  if (process.env.REACT_APP_ENV === "production") {
    return props.posthogKey ? isFeatureEnabled ? props.children : null : props.children;
  }
  return props.children;
};
export default FeatureGuard;