import React from "react";
import { EmptyData as EmptyDataImage } from "assets";
type Props = {
  title: string;
  description?: string;
};
const EmptyData = (props: Props) => {
  const {
    title = "",
    description = "You havent made any transactions to your account yet."
  } = props;
  return <div className="flex flex-col w-full items-center justify-center p-4" data-sentry-component="EmptyData" data-sentry-source-file="EmptyData.tsx">
      <img src={EmptyDataImage} className="w-40 h-40 text-blue-500 " />

      <h4 className="mt-2 text-base font-semibold leading-6 text-gray-900 gap-x-2">
        <span className="capitalize mr-[3px]">{title}</span>
      </h4>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
    </div>;
};
export default EmptyData;