import { ComingSoon } from "components/ComingSoon";
import LoadingScreen from "components/LoadingScreen";
import { useInit } from "hooks/useInit";
import { ComponentType, Suspense, lazy } from "react";
import { lazyRetry } from "utils/lazyRetry";
export const Loadable = <P extends object,>(Component: ComponentType<P>): React.FC<P> => {
  const WrappedComponent: React.FC<P> = props => {
    return <Suspense fallback={<LoadingScreen />} data-sentry-element="Suspense" data-sentry-component="WrappedComponent" data-sentry-source-file="elements.tsx">
        {<Component {...props} />}
      </Suspense>;
  };
  WrappedComponent.displayName = `Loadable(${Component.displayName || Component.name || "Component"})`;
  return WrappedComponent;
};

//AUTH
export const LoginPage = Loadable(lazy(() => lazyRetry(() => import("pages/auth/Login"))));
export const ForgotPasswordPage = Loadable(lazy(() => lazyRetry(() => import("pages/auth/ForgotPassword"))));
export const SignupPage = Loadable(lazy(() => lazyRetry(() => import("pages/auth/Signup"))));
export const OTPVerificationPage = Loadable(lazy(() => lazyRetry(() => import("pages/auth/OTPVerification"))));

//APP
export const DashboardPage = Loadable(lazy(() => lazyRetry(() => import("pages/app/MainDashboard"))));
export const PortfolioPage = Loadable(lazy(() => lazyRetry(() => import("pages/app/Portfolio"))));
export const PortfolioDetailsPage = Loadable(lazy(() => lazyRetry(() => import("pages/app/PortfolioDetails"))));
export const SettingsPage = Loadable(lazy(() => lazyRetry(() => import("pages/app/Settings"))));
export const MutualFundsPage = Loadable(lazy(() => lazyRetry(() => import("pages/app/MutualFunds"))));
export const MutualFundDetailsPage = Loadable(lazy(() => lazyRetry(() => import("pages/app/MutualFundDetails"))));
export const TransactionsPage = Loadable(lazy(() => lazyRetry(() => import("pages/app/Transactions"))));
export const KYCPage = Loadable(lazy(() => lazyRetry(() => import("pages/app/KYC"))));
export const KYCAdminPreviewPage = Loadable(lazy(() => lazyRetry(() => import("pages/app/KYCAdminPreview"))));
export const MFAPage = Loadable(lazy(() => lazyRetry(() => import("pages/app/MFA"))));
export const UploadInvoice = Loadable(lazy(() => lazyRetry(() => import("pages/app/Invoice/UploadInvoice"))));
export const EditInvoice = Loadable(lazy(() => lazyRetry(() => import("pages/app/Invoice/EditInvoice"))));
export const CreateInvoice = Loadable(lazy(() => lazyRetry(() => import("pages/app/Invoice/CreateInvoice"))));
export const WithdrawTransactionsList = Loadable(lazy(() => lazyRetry(() => import("pages/app/Withdraw"))));
export const InvoicingPage = Loadable(lazy(() => lazyRetry(() => import("pages/app/Invoicing/Invoicing"))));
export const UserProfilePage = Loadable(lazy(() => lazyRetry(() => import("pages/app/UserProfile/UserProfile"))));
export const MaintenancePage = Loadable(lazy(() => lazyRetry(() => import("pages/misc/MaintainancePage"))));
export const KYCVersion2Page = Loadable(lazy(() => lazyRetry(() => import("pages/app/KYC-V2/KYCVersion2"))));
export const ComingSoonPage = ComingSoon;