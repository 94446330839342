import "./utils/sentry";
import { ErrorBoundary, withProfiler } from "@sentry/react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import React, { useEffect } from "react";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Toaster } from "design-system/components/ui/toaster";
import { queryClient } from "apis";
import analyticsManager from "./services/analytics-manager";
// import router from "./router/index-3";
import { setAuthorizationHeader } from "utils/api";
import { useToast } from "design-system/components/ui";
import { usePageTracking } from "hooks/usePageTracking";
import SnackbarProvider, { useSnackbar } from "./components/Snackbar";


import Router from "./router";
import { ConfigProvider } from "antd";
import { AntdThemeConfig } from "config/antd";
import { SnackbarUtilsConfigurator } from "utils/snackbar";
import { InitProvider } from "context/InitProvider";
import { UserProvider } from "context/UserProvider";
import MobileWarningBanner from "components/MobileWarningBanner";
import ErrorFallback from "components/ErrorFallback";
import { KYCProvider } from "context/KYCProvider";



const App = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Inter",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "1rem",
          },
        },
        defaultProps: {
          elevation: 0,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "0.5rem",
          },
        },
      },
    },
  });

  useEffect(() => {
    analyticsManager.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    const cookieString = document.cookie;
    const sessionToken = sessionStorage.getItem("token");
    const localToken = localStorage.getItem("token");
    if (cookieString.length == 0 && sessionToken && localToken) {
      localStorage.clear();
      sessionStorage.clear();
      enqueueSnackbar("User Session Expired, Please login again.", {
        variant: "error",
      });
    } else {
      setAuthorizationHeader(localStorage.getItem("token"));
    }
  }, []);


  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>} showDialog>
      <ReactErrorBoundary
        fallbackRender={ErrorFallback}
      >
      <MobileWarningBanner />
      <UserProvider>
      <ConfigProvider theme={AntdThemeConfig}>
        <ThemeProvider theme={theme}>
          <InitProvider>
            <KYCProvider>
            <SnackbarProvider>
              <SnackbarUtilsConfigurator />
              <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                  <Router />
                </QueryClientProvider>
              </BrowserRouter>
            </SnackbarProvider>
            </KYCProvider>
          </InitProvider>
        </ThemeProvider>
      </ConfigProvider>
      <Toaster />
      </UserProvider>
      </ReactErrorBoundary>
    </ErrorBoundary>
  );
};

export default withProfiler(App);
