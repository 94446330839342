// utils/form-extractor.ts

import { KYCFormData } from 'types/form';

type PathImpl<T, K extends keyof T> =
  K extends string
  ? T[K] extends Record<string, any>
    ? T[K] extends ArrayLike<any>
      ? K | `${K}.${PathImpl<T[K], Exclude<keyof T[K], keyof any[]>>}`
      : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
    : K
  : never;

export type Path<T> = PathImpl<T, keyof T> | keyof T | string;


function get(obj: any, path: string, defaultValue?: any): any {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);

  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
}

// Helper function to get all leaf values with their original property names
function getAllLeafValues(obj: any): any {
  return Object.entries(obj).reduce((acc: any, [key, value]) => {
    // Handle File objects
    if (value instanceof File) {
      acc[key] = value;
      return acc;
    }
    
    // Handle arrays
    if (Array.isArray(value)) {
      acc[key] = value;
      return acc;
    }
    
    // Handle nested objects
    if (typeof value === 'object' && value !== null) {
      return { ...acc, ...getAllLeafValues(value) };
    }
    
    acc[key] = value;
    return acc;
  }, {});
}
export function extractFormFields<
  T extends KYCFormData,
  K extends Path<T>[]
>(
  formData: Partial<T>,
  keys: K
): { [key: string]: any } {
  // If keys array is empty, extract all leaf values
  if (keys.length === 0) {
    return getAllLeafValues(formData);
  }
  return keys.reduce((acc, key) => {
    const value = get(formData, key.toString());
    if (value !== undefined) {
      const fieldName = key.toString().split('.').pop() || '';
      acc[fieldName] = value;
    }
    return acc;
  }, {} as { [key: string]: any });
}



type FormDataValue = string | number | Date | File | Blob | null | undefined;
export const objectToFormData = (
    obj: Record<string, FormDataValue | FormDataValue[]>,
    formData = new FormData(),
    parentKey = ''
  ): FormData => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const formKey = parentKey ? `${parentKey}[${key}]` : key;
  
        if (value instanceof File || value instanceof Blob) {
          formData.append(formKey, value);
        } else if (Array.isArray(value)) {
          // Convert array to JSON string
          formData.append(formKey, JSON.stringify(value));
        } else if (value instanceof Date) {
          formData.append(formKey, value.toISOString());
        } 
        else if (typeof value === 'object' && value !== null && value !== undefined) {
          formData.append(formKey, JSON.stringify(value));
        }
        else if (value !== null && value !== undefined) {
          formData.append(formKey, String(value));
        }
      }
    }
    return formData;
  };




export const convertToFormData = (obj: any): FormData => {
    const formData = new FormData();
    
    const appendToFormData = (data: any, parentKey?: string) => {
      if (data && typeof data === 'object' && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          const value = data[key];
          const newKey = parentKey ? `${parentKey}[${key}]` : key;
          
          if (value instanceof File) {
            formData.append(newKey, value);
          } else if (Array.isArray(value)) {
            value.forEach((item, index) => {
              const arrayKey = `${newKey}[${index}]`;
              if (item instanceof File) {
                formData.append(arrayKey, item);
              } else {
                appendToFormData(item, arrayKey);
              }
            });
          } else if (value && typeof value === 'object') {
            appendToFormData(value, newKey);
          } else if (value !== null && value !== undefined) {
            formData.append(newKey, String(value));
          }
        });
      } else if (data !== null && data !== undefined) {
        formData.append(parentKey || '', data);
      }
    };
  
    appendToFormData(obj);
    return formData;
  };

  type NestedObject = {
    [key: string]: any;
  };

  export const nullifyKeys = (obj: NestedObject, keys: string[]): NestedObject => {
    const result = { ...obj }; // Use shallow copy instead of deep copy to preserve File objects
  
    keys.forEach(path => {
      const parts = path.split('.');
      let current = result;
  
      // Handle single-level key
      if (parts.length === 1) {
        const value = current[path];
        // Don't modify File objects, only nullify if not a File
        if (!(value instanceof File)) {
          current[path] = null;
        }
        return;
      }
  
      // Handle nested paths
      for (let i = 0; i < parts.length - 1; i++) {
        if (!current[parts[i]]) {
          return; // Skip if the path doesn't exist
        }
        current = current[parts[i]];
      }
  
      const lastPart = parts[parts.length - 1];
      const value = current[lastPart];
      // Don't modify File objects, only nullify if not a File
      if (!(value instanceof File)) {
        current[lastPart] = null;
      }
    });
  
    return result;
  };
    