import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { cn } from "design-system/utils";
const TooltipProvider = TooltipPrimitive.Provider;
interface TooltipProps {
  children: React.ReactElement;
  content: React.ReactNode;
  className?: string;
}
function Tooltip({
  children,
  content,
  className
}: TooltipProps) {
  return <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">
      <TooltipPrimitive.Root delayDuration={0} data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx">
        <TooltipPrimitive.Trigger asChild data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx">{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx">
          <TooltipPrimitive.Content className={cn("z-50 overflow-hidden max-w-[200px] text-wrap rounded-md bg-black/80 px-3 py-1.5 text-xs text-white shadow-md animate-in-fasts fade-in-fasts zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", className)} sideOffset={4} data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx">
            {content}
            <TooltipPrimitive.Arrow className="fill-current text-black/80" data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx" />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipProvider>;
}
export default Tooltip;