import { AxiosRequestConfig } from "axios"
import { API_URLS } from "constants/api-urls"
import { KYCFormData } from "types/form"
import { torquePrivateApi, userServiceApi } from "utils/api"





export const KYCService={
    GetKYCDetails:function(params?:AxiosRequestConfig){
        return userServiceApi.get(API_URLS.kyc.create,params)
    },
    CreateKYC:function(payload:FormData,params?:AxiosRequestConfig){
        return userServiceApi.post(API_URLS.kyc.create,payload,params)
    },
    TriggerSubmitKYC:function(){
        return userServiceApi.put(API_URLS.kyc.create)
    },
    CreatePartnerKYC:function(payload:FormData,params?:AxiosRequestConfig){
        return userServiceApi.post(API_URLS.kyc.partner,payload,params)
    },
    DeletePartnerKYC:function(id:number,params?:AxiosRequestConfig){
        return userServiceApi.delete(API_URLS.kyc.partner+`/${id}`,params)
    },
    UpdateUBO:function(id:number,params?:AxiosRequestConfig){
        return userServiceApi.put(API_URLS.kyc.ubo+`/${id}`,params)
    }
}
