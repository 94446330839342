import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft, X } from "lucide-react";
import { Button } from "design-system/components/ui";
import { useAppStore } from "store";
import { PATH_DASHBOARD } from "router/path";
import { Logo } from "components/Logo";
interface Props {
  title?: string;
  titleRender?: React.ReactNode;
  onClose?: () => void;
  closeRender?: React.ReactNode;
}
const NavTop = (props: Props) => {
  const navigate = useNavigate();
  function defaultClose() {
    // navigate(-1);
  }
  return <div className="sticky z-50 h-[80px] top-0 flex items-center justify-between w-full p-3 px-5 md:p-5 bg-white border-b border-gray-200" data-sentry-component="NavTop" data-sentry-source-file="NavTop.tsx">
      <div className="flex h-16 shrink-0 items-center"></div>

      {props.titleRender ? <div>{props.titleRender}</div> : <div className="w-full max-w-md text-center sm:text-center text-md md:text-xl font-semibold text-dark-black">
          {props.title}
        </div>}

      {props.closeRender ? props.closeRender : <Button onClick={props.onClose ? props.onClose : defaultClose} variant="ghost" size="icon" className="rounded rounded-full text-dark-black hover:text-white">
          <X size={24} />
        </Button>}
    </div>;
};
export default NavTop;