import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { FundReturn } from "apis/funds";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  redraw: true,
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
        zeroLineColor: "transparent"
      },
      ticks: {
        display: false,
        borderWidth: 0
      }
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
        zeroLineColor: "transparent"
      },
      ticks: {
        stepSize: 0.1,
        display: false,
        borderWidth: 0
      },
      min: 79,
      max: 85
    }
  },
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    }
  }
};
const monthLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
export const makeData = (name: string, data: FundReturn[] = [], theme: {
  color: string;
  backgroundGradient: string;
  backgroundForChart: string;
}) => {
  const makeLabels = (data: FundReturn[]) => {
    return data.map(({
      month,
      year
    }) => `${monthLabels[month - 1]} '${year.toString().slice(2)}`);
  };
  const makeAxisData = (data: FundReturn[]) => {
    return data.map(({
      returns
    }) => returns);
  };
  return {
    labels: makeLabels(data),
    datasets: [{
      label: name,
      data: makeAxisData(data),
      borderWidth: 2,
      borderColor: theme.color,
      backgroundColor: theme.backgroundForChart,
      pointRadius: 1,
      // Removes the dots
      fill: {
        backgroundColor: "green",
        target: "origin"
      },
      tension: 0.3
    }]
  };
};
const Chart = ({
  name,
  data,
  theme
}: {
  theme: {
    color: string;
    backgroundGradient: string;
    backgroundForChart: string;
  };
  name?: string;
  data: FundReturn[];
}) => {
  const d = makeData(name = "", data, theme);
  return <Line options={options} data={d} data-sentry-element="Line" data-sentry-component="Chart" data-sentry-source-file="Chart.tsx" />;
};
export default Chart;