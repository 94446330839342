import React, { useState } from "react";
import { Dialog, DialogContent } from "design-system/components/ui/dialog";
import { Icon } from "@iconify/react";
import { useKYC } from "hooks/useKYC";
import { KYCJourneySteps } from "constants/kyc";
import { Button } from "design-system/components/ui-v2";
interface ReviewKYCProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  excludedKeys?: string[];
}
const ReviewKYC = ({
  open,
  onClose,
  onSubmit,
  excludedKeys = []
}: ReviewKYCProps) => {
  const {
    serverData,
    setCurrentStep,
    journey
  } = useKYC();
  const [isLoading, setIsLoading] = useState(false);
  const handleEditSection = (step: KYCJourneySteps) => {
    const stepIndex = journey.indexOf(step);
    if (stepIndex !== -1) {
      setCurrentStep(stepIndex);
      onClose();
    }
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
    onClose();
  };
  const Section = ({
    title,
    data,
    step
  }: {
    title: string;
    data: any;
    step: KYCJourneySteps;
  }) => <div className="border border-gray-200 rounded-lg p-4 mb-4" data-sentry-component="Section" data-sentry-source-file="ReviewKYC.tsx">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
        <button onClick={() => handleEditSection(step)} className="text-blue-600 hover:text-blue-800 transition-colors">
          <Icon icon="lucide:edit" className="w-5 h-5" data-sentry-element="Icon" data-sentry-source-file="ReviewKYC.tsx" />
        </button>
      </div>

      <div className="space-y-2">
        {Object.entries(data || {}).filter(([key]) => !excludedKeys.includes(key)).map(([key, value]) => <div key={key}>
              {key === "businessAddress" && typeof value === "string" ? <>
                  <div className="text-sm text-gray-600 font-medium capitalize mb-2">
                    {key.replace(/([A-Z])/g, " $1").trim()}
                  </div>
                  <div className="pl-4 space-y-2">
                    {Object.entries(JSON.parse((value as string))).filter(([_, addressValue]) => addressValue) // Only show if value exists
            .map(([addressKey, addressValue]) => <div key={addressKey} className="grid grid-cols-2 gap-4">
                          <span className="text-sm text-gray-600 font-medium capitalize">
                            {addressKey.replace(/([A-Z])/g, " $1").trim()}
                          </span>
                          <span className="text-sm text-gray-900 break-words">
                            {(addressValue as string)}
                          </span>
                        </div>)}
                  </div>
                </> : typeof value === "object" && value !== null ? <>
                  <div className="text-sm text-gray-600 font-medium capitalize mb-2">
                    {key.replace(/([A-Z])/g, " $1").trim()}
                  </div>
                  <div className="pl-4 space-y-2">
                    {Object.entries(value).filter(([_, nestedValue]) => nestedValue) // Only show if value exists
            .map(([nestedKey, nestedValue]) => <div key={nestedKey} className="grid grid-cols-2 gap-4">
                          <span className="text-sm text-gray-600 font-medium capitalize">
                            {nestedKey.replace(/([A-Z])/g, " $1").trim()}
                          </span>
                          <span className="text-sm text-gray-900 break-words">
                            {(nestedValue as string)}
                          </span>
                        </div>)}
                  </div>
                </> : value ?
        // Only render if value exists
        <div className="grid grid-cols-2 gap-4">
                  <span className="text-sm text-gray-600 font-medium capitalize">
                    {key.replace(/([A-Z])/g, " $1").trim()}
                  </span>
                  <span className="text-sm text-gray-900 break-words">
                    {(value as string)}
                  </span>
                </div> : null}
            </div>)}
      </div>
    </div>;
  return <Dialog open={open} onOpenChange={onClose} data-sentry-element="Dialog" data-sentry-component="ReviewKYC" data-sentry-source-file="ReviewKYC.tsx">
      <DialogContent className="w-full md:max-w-3xl" showCloseButton={false} data-sentry-element="DialogContent" data-sentry-source-file="ReviewKYC.tsx">
        <div className="flex items-center justify-center">
          <div className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold text-gray-900">
                Review KYC Details
              </h2>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition-colors">
                <Icon icon="lucide:x" className="w-6 h-6" data-sentry-element="Icon" data-sentry-source-file="ReviewKYC.tsx" />
              </button>
            </div>

            {serverData && <div className="space-y-6">
                <Section title="Basic Details" data={serverData[KYCJourneySteps.userDetails]} step={KYCJourneySteps.userDetails} />

                <Section title="Business Details" data={serverData[KYCJourneySteps.businessDetails]} step={KYCJourneySteps.businessDetails} />

                <Section title="Bank Details" data={serverData[KYCJourneySteps.bankDetails]} step={KYCJourneySteps.bankDetails} />

                <Section title="Documents" data={serverData[KYCJourneySteps.documents]} step={KYCJourneySteps.documents} />
              </div>}
            <div className="mt-6 flex justify-end gap-3 border-t pt-4">
              <Button variant="outline" onClick={onClose} data-sentry-element="Button" data-sentry-source-file="ReviewKYC.tsx">
                Cancel
              </Button>
              <Button isLoading={isLoading} onClick={handleSubmit} data-sentry-element="Button" data-sentry-source-file="ReviewKYC.tsx">
                Submit KYC
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>;
};
export default ReviewKYC;