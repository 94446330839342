import { AxiosRequestConfig } from "axios";
import { API_URLS } from "constants/api-urls";
import { Address } from "types/form";
import { userServiceApi } from "utils/api";


interface AccountData {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string | null;
  phoneNumber: string;
  address: string;
}

export interface UserResponse {
  activeServices: number[];
  accountData: AccountData;
  userAlerts: number[];
  kycStatus: string;

}

export interface InitiateServiceRequestRequest {
  productId: number;
}

export interface BankAccountDetailsResponse {
  bankAccountName: string;
  accountNumber: string;
  ifscCode: string;
}

export interface FetchUserDetailsResponse extends UserResponse {
  httpStatus: "OK";
};

const UserService = {
  getUserDetails: async function (params?: AxiosRequestConfig) {
    const res = await userServiceApi.get<FetchUserDetailsResponse>(API_URLS.user.userDetails, params);
    return res;
  },
  initiateServiceRequest: async function (payload: InitiateServiceRequestRequest) {
    const res = await userServiceApi.put(API_URLS.user.alerts, payload);
    return res;
  },
  getUserBankAccountDetails: async function (params?: AxiosRequestConfig) {
    const res = await userServiceApi.get(API_URLS.user.bankAccountDetails, params);
    return res;
  }
};


export default UserService;