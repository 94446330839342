import { API_URLS } from "constants/api-urls";
import { api, userServiceApi } from "utils/api";

export type FetchAccountBalanceResponse = {
  httpStatus: "OK";
  data: {
    investedAmount: number;
    currentAmount: number;
    returns: number | null;
    yield: string;
  };
};

export interface PaymentType {
  name: string | null;
  accountNumber: string;
  routing: string;
  bankName: string;
  type: "FEDWIRE" | "ACH" | "SWIFT"; // Enum-like structure for payment types
  accountType: string;
  bankAddress: string;

}

export interface AccountDetails {
  accountNumber: string;
  countryId: number;
  paymentType: PaymentType[];
}

export type BankAccountStatus = {
  status: string | null;
  accountNumber: string;
  countryId: number;
};

export type FetchBankAccountsResponse = {
  httpStatus: "OK";
  data: AccountDetails[];
};
export type FetchBankAccountStatusResponse = {
  httpStatus: "OK";
  data: BankAccountStatus[];
};

export const AccountService = {
  getBalance: function () {
    return api<FetchAccountBalanceResponse>(`/balance`);
  },
  getBankAccounts: function () {
    return userServiceApi.get<FetchBankAccountsResponse>(
      API_URLS.user.bankAccounts
    );
  },
  getBankAccountStatus: function () {
    return userServiceApi.get<FetchBankAccountStatusResponse>(
      API_URLS.user.bankAccountStatus
    );
  },
};

export default AccountService;
