import React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "design-system/utils";

// Add loading spinner component (you can adjust the styling as needed)
const LoadingSpinner = () => <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-component="LoadingSpinner" data-sentry-source-file="IconButton.tsx">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="IconButton.tsx" />
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" data-sentry-element="path" data-sentry-source-file="IconButton.tsx" />
  </svg>;

// Define variants using class-variance-authority
const buttonVariants = cva("inline-flex items-center justify-center rounded-full transition-transform duration-200 ease-in-out focus:outline-none ", {
  variants: {
    size: {
      sm: "p-1 text-sm",
      md: "p-2 text-base",
      lg: "p-3 text-lg"
    },
    variant: {
      primary: "bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500",
      secondary: "bg-gray-500 text-white hover:bg-gray-600 focus:ring-gray-500",
      outline: "border border-gray-300 text-gray-700 hover:bg-gray-100 focus:ring-gray-300",
      ghost: "bg-transparent hover:bg-gray-100 focus:ring-gray-200"
    }
  },
  defaultVariants: {
    size: "md",
    variant: "primary"
  }
});
interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  icon: React.ReactNode;
  disabled?: boolean;
  loading?: boolean; // Add loading prop
  className?: string;
}
const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  size,
  variant,
  disabled = false,
  loading = false,
  // Add loading prop with default value
  className
}) => {
  return <button onClick={onClick} disabled={disabled || loading} // Disable button when loading
  className={cn(buttonVariants({
    size,
    variant
  }), !(disabled || loading) && "hover:scale-110 active:scale-95", (disabled || loading) && "cursor-not-allowed opacity-50", className)} data-sentry-component="IconButton" data-sentry-source-file="IconButton.tsx">
      {loading ? <LoadingSpinner /> : icon}
    </button>;
};
export default IconButton;