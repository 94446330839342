import { KYCFormData } from "types/form";
import { Path } from "utils/form-extractor";

export enum KYCJourneySteps {
    userDetails = "userDetails",
    businessDetails = "businessDetails",
    partnersDetails = "partnersDetails",
    directorDetails = "directorDetails",
    bankDetails = "bankDetails",
    documents = "documents",
  }
  
  // Type for valid KYC form data keys
  export type KYCFormDataKeys = Path<KYCFormData>;
  
  export type BusinessTypeProps =
    | "freelancer"
    | "company"
    | "llp"
    | "proprietorship"
    | "partnership";
  
  export enum BusinessTypeEnum {
    freelancer = "freelancer",
    company = "company",
    llp = "llp",
    proprietorship = "proprietorship",
    partnership = "partnership",
  }
  
  export type KYCJourneyStepsProps =
    | "userDetails"
    | "businessDetails"
    | "partnersDetails"
    | "directorDetails"
    | "bankDetails"
    | "documents";