import React, { useState } from "react";
import { Dialog, DialogContent } from "design-system/components/ui/dialog";
import { Button } from "design-system/components/ui-v2";
import { Icon } from "@iconify/react";
type Props = {
  open: boolean;
  onClose?: () => void;
  title: string;
  description: string;
  onSubmit?: () => void;
  submitButtonText?: string;
  icon?: string;
};
const UploadInvoiceDialog = (props: Props) => {
  const {
    open,
    onClose,
    title,
    description,
    onSubmit,
    submitButtonText = "Submit",
    icon = "material-symbols:description-outline"
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await props.onSubmit?.();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  return <Dialog open={open} onOpenChange={onClose} data-sentry-element="Dialog" data-sentry-component="UploadInvoiceDialog" data-sentry-source-file="DefaultDialogBox.tsx">
      <DialogContent className="w-full md:w-[450px]" data-sentry-element="DialogContent" data-sentry-source-file="DefaultDialogBox.tsx">
        <div className="flex flex-col items-center justify-center py-6 text-center">
          {/* Icon Container */}
          <div className="flex items-center justify-center w-16 h-16 mb-4 bg-blue-50 rounded-full">
            <Icon icon={icon} className="w-8 h-8 text-blue-600" data-sentry-element="Icon" data-sentry-source-file="DefaultDialogBox.tsx" />
          </div>

          {/* Title */}
          <h2 className="mb-2 text-2xl font-semibold text-gray-900">{title}</h2>

          {/* Description */}
          <p className="mb-6 text-gray-600">{description}</p>

          {/* Action Button */}
          {onSubmit && <Button variant="primary" className="w-full" isLoading={isLoading} onClick={handleSubmit}>
              {submitButtonText}
            </Button>}
        </div>
      </DialogContent>
    </Dialog>;
};
export default UploadInvoiceDialog;