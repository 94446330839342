// src/components/ErrorComponent.tsx
import { Button } from "design-system/components/ui-v2";
import React from "react";
import LoadingScreen from "./LoadingScreen";
interface ErrorComponentProps {
  message?: string;
  description?: string;
  onRetry?: () => void;
  children?: React.ReactNode;
  error?: any;
  isLoading?: boolean;
}
const ErrorComponent: React.FC<ErrorComponentProps> = ({
  message = "Something went wrong",
  description,
  onRetry,
  children,
  error,
  isLoading
}) => {
  if (isLoading) {
    return <LoadingScreen />;
  } else if (error) {
    return <div className="flex h-full flex-col items-center justify-center p-4 text-center">
        <div className="text-red-500 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <p className="text-gray-700 text-lg font-medium mb-4">{message}</p>
        {description && <p className="text-gray-600 text-sm font-normal mb-4">
            {description}
          </p>}
        {onRetry && <Button onClick={onRetry} isLoading={isLoading} variant="ghost" className="text-default-accent">
            Try Again
          </Button>}
      </div>;
  } else {
    return children;
  }
};
export default ErrorComponent;